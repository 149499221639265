import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  Box,
  Card,
  Container,
  Flex,
  Heading,
  Image,
  Layout,
  Text,
  MediumAndAbove,
  SmallAndBelow
} from '../components'

export interface CompanyTemplateProps {
  seoTitle: string
  metaDescription: string
  intro: {
    heading: string
    subheading: string
    text: string
    image: string
  }
  history: {
    heading: string
    subheading: string
    text: string
    timeline: Array<{
      year: string
      text: string
    }>
  }
  vision: {
    heading: string
    subheading: string
    text: string
    image: string
  }
}

const TimelineSegment: React.FC<{
  year: string
  text: string
  isEven: boolean
}> = ({ year, text, isEven }) => {
  return (
    <Flex flex="1" alignSelf={isEven ? 'flex-start' : 'flex-end'}>
      <Image
        src="/img/timeline-pole.svg"
        alt=""
        transform={`rotate(${isEven ? 0 : 180}deg)`}
        width="41"
        height="223"
      />
      <Box alignSelf={isEven ? 'flex-start' : 'flex-end'} maxWidth="270">
        {isEven && (
          <Text size="xxlarge" weight="bold">
            {year}
          </Text>
        )}
        <Text my="xxsmall" whiteSpace="break-spaces">
          {text}
        </Text>
        {!isEven && (
          <Text size="xxlarge" weight="bold">
            {year}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

const CompanyPageTemplate: React.FC<CompanyTemplateProps> = ({
  seoTitle,
  metaDescription,
  intro,
  history,
  vision
}) => {
  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box py="100">
        <Container>
          <Flex wrap="wrap">
            <Box
              width={[1, 1, 1 / 2]}
              backgroundImage={`url('${intro.image}')`}
              backgroundSize="cover"
              backgroundPosition="center"
              height="256"
              borderRadius="30"
              mb="xlarge"
            />
            <Box width={[1, 1, 1 / 2]} pl={['none', 'none', 'large']}>
              <Heading level="4" textTransform="uppercase" color="accent">
                {intro.subheading}
              </Heading>
              <Heading level="2" textTransform="uppercase" mb="large">
                {intro.heading}
              </Heading>
              <Text size="small" whiteSpace="break-spaces">
                {intro.text}
              </Text>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box mb="100">
        <Container>
          <Heading level="4" textTransform="uppercase" color="accent">
            {history.subheading}
          </Heading>
          <Heading level="2" textTransform="uppercase" mb="large">
            {history.heading}
          </Heading>
          <Text size="small" whiteSpace="break-spaces" mb="xxlarge">
            {history.text}
          </Text>
          <MediumAndAbove height="400">
            <Flex transform="translateY(20px)">
              {history.timeline
                .filter((_, index) => index % 2 === 0)
                .map(({ year, text }) => (
                  <TimelineSegment key={year} year={year} text={text} isEven />
                ))}
            </Flex>
            <Box height="1" bg="black" ml="20" width="66%" />
            <Flex pl="15%" width="85%" transform="translateY(-20px)">
              {history.timeline
                .filter((_, index) => index % 2 !== 0)
                .map(({ year, text }) => (
                  <TimelineSegment
                    key={year}
                    year={year}
                    text={text}
                    isEven={false}
                  />
                ))}
            </Flex>
          </MediumAndAbove>
          <SmallAndBelow position="relative" px={['none', 'large']}>
            <Image
              src="/img/down-silhouette.svg"
              alt=""
              position="absolute"
              bottom="-35"
              left="50%"
              transform="translateX(-50%)"
            />
            {history.timeline.map(({ year, text }) => (
              <Card
                key={year}
                py="xxlarge"
                px={['large', 'xlarge']}
                mb="medium"
                position="relative"
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Image src="/img/timeline-point.svg" alt="" mr="xlarge" />
                  <Text size="xxlarge" weight="bold" mr="xlarge">
                    {year}
                  </Text>
                  <Text size="large" flex="1">
                    {text}
                  </Text>
                </Flex>
              </Card>
            ))}
          </SmallAndBelow>
        </Container>
      </Box>
      <Box mb="100">
        <Container>
          <Flex wrap="wrap">
            <Box width={['100%', '100%', '60%']} pr={['none', 'none', 'large']}>
              <Heading level="4" textTransform="uppercase" color="accent">
                {vision.subheading}
              </Heading>
              <Heading level="2" textTransform="uppercase" mb="large">
                {vision.heading}
              </Heading>
              <Text size="small" whiteSpace="break-spaces">
                {vision.text}
              </Text>
            </Box>
            <Flex justifyContent="center" width={['100%', '100%', '40%']}>
              <Image
                src={vision.image}
                alt="infographic"
                display="block"
                mr="-45"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  )
}

interface CompanyPageProps {
  data: {
    markdownRemark: {
      frontmatter: CompanyTemplateProps
    }
  }
}

const CompanyPage: React.FC<CompanyPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <CompanyPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CompanyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "company" } }) {
      frontmatter {
        seoTitle
        metaDescription
        intro {
          heading
          subheading
          text
          image
        }
        history {
          heading
          subheading
          text
          timeline {
            year
            text
          }
        }
        vision {
          heading
          subheading
          text
          image
        }
      }
    }
  }
`

export { CompanyPageTemplate }
export default CompanyPage
